<template>
  <FeatureCard id="contact">
    <FeatureCardSection>
      <p class="no-margin text-weight-bold q-pl-md">Jetzt Kontakt aufnehmen</p>
    </FeatureCardSection>
    <FeatureCardSection>
      <q-list class="text-body1">
        <q-item>
          <q-item-section avatar>
            <q-icon name="email" color="accent" />
          </q-item-section>
          <q-item-section class="text-weight-medium">
            <a href="mailto:contact@tailord.cloud">contact@tailord.cloud</a>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section avatar>
            <q-icon name="group" color="accent" />
          </q-item-section>
          <q-item-section class="text-weight-medium">
            Daniel Augustin und Peter Ewinger
          </q-item-section>
        </q-item>
      </q-list>
    </FeatureCardSection>
  </FeatureCard>
</template>

<script>
import {Component, Vue} from 'vue-property-decorator';
import FeatureCard from '@/components/layout/FeatureCard.vue';
import FeatureCardSection from '@/components/layout/FeatureCardSection.vue'

@Component({
  components: {
    FeatureCard,
    FeatureCardSection
  }
})
export default class ContactInfo extends Vue{

}
</script>

<style>

</style>