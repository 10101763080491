<template>
  <q-card class="bg-translucent text-dark shadow-3 text-h5">
      <slot />
  </q-card>
</template>

<script>
import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {

  }
})
export default class FeatureCard extends Vue{

}
</script>

<style lang="sass">
.bg-translucent
  backdrop-filter: blur(8px)
  -webkit-backdrop-filter: blur(8px)
  background-color: #FFFFFF8D!important
  border-radius: 11px!important
  border-color: #FFFFFF70!important
  border-width: 1px!important
  border-style: solid!important
</style>