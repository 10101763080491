<template>
  <div class="fixed-top-left q-mt-lg" style="z-index: 100">    
    <div class=" border blur-12-grey-2 shadow-4" style="height: 70px; width: 220px">
      
    </div>
    <div class="text-h2 q-px-sm z-max text-weight-medium" style="position: relative; bottom: 1.15em">
      <span class="black">Tailo</span><span class="red">RD</span> 
    </div>
  </div> 
</template>

<script>
import Vue from "vue";
import Component from 'vue-class-component';

@Component
export default class Logo extends Vue {

}
</script>

<style lang="sass">
  .border
    font-family: Roboto, Arial, Helvetica, sans-serif
    border: 1px solid #525151
    border-left: none
    border-top-right-radius: 12px
    border-bottom-right-radius: 12px
    display: inline-block
  
  .blur-12-grey-2
    backdrop-filter: blur(12px)
    background-color: #f5f5f58f
  .black
    color: #525151

  .red
    color: #ED8D69
</style>