<template>
  <FeatureCard>
    <FeatureCardSection>
      Der Funktionsumfang für die kostenfreie  RD Refill Version ist:
      <br>
      <ul>
        <li>Beschränkt auf ein Fahrzeug</li>
        <li>Materiallisten sind nur für einen Mitarbeiteraccount sichtbar</li>
        <li>Anzeige des Füllstandes einer Sauerstofflasche</li>
        <li>Schnellauswahl für für häufig gebrauchte Materialien</li>
        <li>Suchfunktion mit Vorschlägen für häufig gebrauchte Materialien</li>
        <li>Favoritenfunktion für häufig gebrauchte Materialien</li>
        <li>Auswählbare Materialien sind beschränkt auf die TailoRD Basisdatenbank</li>
        <li>Werbung</li>
        <li>intuitives Design</li>
      </ul>
      <br>
      Zusätzliche Funktionen sind bei einer Custom RD Refill Version möglich:
      <ul>  
        <li>Handbuch Rettungsdienst</li>
        <li>In Echtzeit Synchronisierte Materiallisten aller Mitarbeiteraccounts</li>
        <li>Vorauswahlen von häufig auftretenden Materialkombinationen</li>
        <li>Es können beliebig viele Fahrzeuge gebucht werden (Kosten je nach Anzahl)</li>
        <li>Auswählbare Materialien sind auf Ihren eigenen Bedarf zugeschnitten</li>
        <li>Es können beliebig viele Sauerstoffflaschen pro Fahrzeug verwaltet werden</li>
        <li>Lokal verfügbare Handlungsstandards, Leitlinien(SOPs), Telefonlisten können individuell integriert werden (Kosten nach Absprache)</li>
        <li>Organisationsspezifisches Design der App (Farben, Logo)</li>
        <li>Eigene Fahrzeuggrafiken (Kosten nach Absprache)</li>
        <li>Die Features in Anführungszeichensind auch anhand der Screenshots nachzuvollziehen.</li>
      </ul>
    </FeatureCardSection>
  </FeatureCard>
</template>

<script>
import {Component, Vue} from 'vue-property-decorator'
import FeatureCard from '@/components/layout/FeatureCard.vue'
import FeatureCardSection from '@/components/layout/FeatureCardSection.vue'

@Component({
  components:{
    FeatureCard,
    FeatureCardSection
  }
})
export default class ExplanationRDRefill extends Vue {

}
</script>

<style>

</style>