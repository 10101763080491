<template>
  <div>    
    <div class="text-h2 q-px-sm z-max text-weight-medium">
      <span class="black">Tailo</span><span class="red">RD</span> 
    </div>
  </div> 
</template>

<script>
import Vue from "vue";
import Component from 'vue-class-component';

@Component
export default class LogoMobile extends Vue {

}
</script>

<style lang="sass">
  .border
    font-family: Roboto, Arial, Helvetica, sans-serif
    border: 1px solid #525151
    border-left: none
    border-top-right-radius: 12px
    border-bottom-right-radius: 12px
    display: inline-block
  
  .opacity
    opacity: 0.6

  .black
    color: #525151

  .red
    color: #ED8D69
</style>