<template>
  <q-card class="bg-translucent-front text-dark shadow-3 text-h5">
    <div class="row q-pt-md q-pr-md">
      <q-space />
      <q-btn icon="close" flat round dense v-close-popup />
    </div>

    <slot />
  </q-card>
</template>

<script>
import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {

  }
})
export default class PopupCard extends Vue{

}
</script>

<style lang="sass">
.bg-translucent-front
  backdrop-filter: blur(5px)
  -webkit-backdrop-filter: blur(5px)
  background-color: #FFFFFFCF!important
  border-radius: 11px!important
  border-color: #FFFFFF70!important
  border-width: 1px!important
  border-style: solid!important
</style>