<template>
  <div class="row items-center">
    <AppDownload provider="google" />
    <AppDownload provider="apple" />  
  </div>
</template>

<script>

import Vue from 'vue';
import Component from 'vue-class-component';
import AppDownload from '@/components/external_links/AppDownload.vue'


@Component({
  components: {
    AppDownload
  }
})
export default class AppDownloadBar extends Vue{

}
</script>