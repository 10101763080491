<template>
  <q-card-section>
    <slot />
  </q-card-section>
</template>

<script>
import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {

  }
})
export default class FeatureCardSection extends Vue{

}
</script>

<style>

</style>